import { Component, ElementRef, Input, OnInit } from '@angular/core'
import { UntypedFormArray, UntypedFormGroup, ValidationErrors } from '@angular/forms'
import { TranslateService } from '@ngx-translate/core'

interface AllValidationErrors {
    controlName: string
    errorName: string
    errorValue: any
}

@Component({
    selector: 'ui-form-errors',
    template: `
        <div *ngIf="show">
            <div class="invalid-feedback" *ngIf="errors.length > 0" style="display: block">
                <span class="clickable" (click)="reveal()"
                    >{{ 'validation.notOk' | translate
                    }}<button type="button" class="btn btn-outline-danger btn-sm  block mt-2">
                        {{ 'validation.showErrors' | translate }}
                    </button></span
                >
                <ng-container *ngIf="showDetails">
                    <div *ngFor="let error of errors" class="text-warn">
                        {{ error | json }}
                    </div>
                </ng-container>
            </div>
            <div *ngIf="errors.length === 0">
                <div class="valid-feedback" style="display: block">
                    {{ 'validation.allOk' | translate }}
                </div>
            </div>
        </div>
    `,
    styles: [
        `
            .clickable {
                cursor: pointer;
            }
        `,
    ],
})
export class FormErrorsComponent implements OnInit {
    @Input() form: UntypedFormGroup
    @Input() formRef: ElementRef
    @Input() show = true
    @Input() showDetails = false
    @Input() messages: Array<any>

    errors: AllValidationErrors[]

    constructor(private translateService: TranslateService) {
        this.errors = []
        this.messages = []
    }

    ngOnInit() {
        this.form.valueChanges.subscribe(() => {
            this.errors = []
            this.calculateErrors(this.form)
        })

        this.calculateErrors(this.form)
    }

    calculateErrors(form: UntypedFormGroup | UntypedFormArray) {
        Object.keys(form.controls).forEach((field) => {
            const control = form.get(field)
            if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
                const groupErrors: ValidationErrors = control.errors
                if (groupErrors !== null) {
                    Object.keys(groupErrors).forEach((keyError) => {
                        this.errors.push({
                            controlName: field,
                            errorName: keyError,
                            errorValue: groupErrors[keyError],
                        })
                    })
                }
                this.errors = this.errors.concat(this.calculateErrors(control))
                return
            }

            const controlErrors: ValidationErrors = control.errors
            if (controlErrors !== null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    this.errors.push({
                        controlName: field,
                        errorName: keyError,
                        errorValue: controlErrors[keyError],
                    })
                })
            }
        })
        if (form.errors) {
            Object.keys(form.errors).forEach((errorKey) => {
                this.errors.push({
                    controlName: form.errors[errorKey].field,
                    errorName: errorKey,
                    errorValue: form.errors[errorKey],
                })
            })
        }
        // This removes duplicates
        this.errors = this.errors.filter(
            (error, index, self) =>
                self.findIndex((t) => {
                    return t.controlName === error.controlName && t.errorName === error.errorName
                }) === index
        )

        return this.errors
    }

    getErrorMessage(error) {
        switch (error.errorName) {
            case 'required':
                return this.translateService.instant('mustFill') + ' ' + this.messages[error.controlName]
            default:
                return 'unknown error ' + error.errorName
        }
    }

    reveal() {
        this.form.markAllAsTouched()
    }
}
