<div
    class="c-sidebar c-sidebar-dark c-sidebar-fixed"
    [ngClass]="{ 'c-sidebar-show': sidebarShowMobile, 'c-sidebar-lg-show': sidebarShowLG }"
    id="sidebar"
>
    <div class="c-sidebar-brand">
        <img class="c-sidebar-brand-full" src="assets/img/brand/logo.png" height="46" alt="Slovgen Logo" />
    </div>
    <ui-inline-profile></ui-inline-profile>
    <ui-menu [menuItems]="menuItems" style="overflow-y: auto"></ui-menu>
    <div class="version">{{ 'common.version' | translate }}: {{ version }}</div>
    <button
        class="c-sidebar-minimizer c-class-toggler"
        type="button"
        data-target="_parent"
        data-class="c-sidebar-unfoldable"
    ></button>
</div>
