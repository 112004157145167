import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable, Injector } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject, Observable, of, throwError } from 'rxjs'
import { catchError, filter, first, switchMap } from 'rxjs/operators'
import { NO_DEFAULT_ERR_HANDLER_CONTEXT } from '../types/types'
import { AuthService } from './auth.service'
import { MessageService } from './message.service'

const whitelist = ['login', 'assets']
const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    private isRefreshing = false
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

    constructor(private inj: Injector, private router: Router, private messageService: MessageService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const skip = whitelist.some((str) => request.url.includes(str))
        if (skip) {
            return next.handle(request).pipe(
                catchError((err) => {
                    this.messageLog(err, request)
                    return throwError(err)
                })
            )
        }
        const authService = this.inj.get(AuthService)
        if (authService.getJwtToken()) {
            request = this.addToken(request, authService.getJwtToken()!)
        }

        return next.handle(request).pipe(
            catchError((error) => {
                this.messageLog(error, request)
                if (error instanceof HttpErrorResponse && 401 === error.status) {
                    return this.handle401Error(request, next)
                } else {
                    return throwError(error)
                }
            })
        )
    }

    private messageLog(error: any, request: HttpRequest<any>) {
        if (request.context.get(NO_DEFAULT_ERR_HANDLER_CONTEXT)) {
            return
        }
        let errorMessage = ''
        if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error: ${error.error.message}`
        } else {
            // server-side error
            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`
        }
        console.log(`ERROR interceptor url ${request.url} err : ${errorMessage}`)
        console.dir(error)
        // this.messageService.addMessage({ message: errorMessage, severity: 'error' })
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                'X-Correlation-ID': this.makeId(10),
                Authorization: `Bearer ${token}`,
            },
        })
    }

    private makeId(length: number) {
        let result = ''

        const charactersLength = characters.length
        for (var i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return result
    }
    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (request.url.includes('refresh')) {
            // ak refresh tokenu fail, redirect to login
            console.log('refreshing access token failed on 401')
            this.router.navigateByUrl('/login')
            return of()
        }
        if (!this.isRefreshing) {
            this.isRefreshing = true
            this.refreshTokenSubject.next(null)
            const authService = this.inj.get(AuthService)
            return authService.refreshToken().pipe(
                first(),
                switchMap((data) => {
                    this.isRefreshing = false
                    const newToken = authService.getJwtToken()
                    this.refreshTokenSubject.next(newToken)

                    if (newToken) {
                        return next.handle(this.addToken(request, newToken))
                    } else {
                        throw throwError(() => `Could not refresh auth`)
                    }
                })
            )
        } else {
            return this.refreshTokenSubject.pipe(
                filter((token) => token != null),
                switchMap((jwt) => {
                    return next.handle(this.addToken(request, jwt))
                })
            )
        }
    }
}
