export class User {
    username: string
    name: string
    surname: string
    roles: string[]
    locale: string
    customerId?: number
    isAdmin = () => {
        return this.roles.includes('admin')
    }
}
