import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core'

import { DefaultLayoutComponent } from '../default-layout'
import { AuthService } from '../../services/auth.service'
import { MenuItem } from '../../../models/menu-item.model'

@Component({
    selector: 'ui-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent implements OnInit {
    @Input()
    menuItems: MenuItem[]
    constructor(private viewport: DefaultLayoutComponent, private auth: AuthService) {}

    ngOnInit() {
        this.menuItems = this.filterMenuItems(this.menuItems)
    }

    filterMenuItems(menuItems: MenuItem[]): MenuItem[] {
        return menuItems
            .filter((item) => !item.roles || this.auth.hasRoleAssigned(item.roles))
            .map((item) => {
                if (item.children) {
                    return { ...item, children: this.filterMenuItems(item.children) }
                } else {
                    return { ...item }
                }
            })
    }

    onAction(event: Event) {
        this.viewport.onMenuClick(event)
    }

    toggleDropdown(id: string) {
        console.log(`debug ${id}  ${document.getElementById(id)}`)
        const hasClass = document.getElementById(id)?.classList.toggle('c-show')
    }
}
