<ui-sidebar
    remove-host
    [menuItems]="sideBarMenuItems"
    [sidebarShowMobile]="sidebarShowMobile"
    [sidebarShowLG]="sidebarShowLG"
></ui-sidebar>
<!-- Sidebar content here -->

<div class="c-wrapper">
    <header class="c-header c-header-dark c-header-fixed">
        <button
            class="c-header-toggler c-class-toggler d-lg-none mfe-auto"
            type="button"
            data-target="#sidebar"
            data-class="c-sidebar-show"
            title="{{ (sidebarShowMobile ? 'menu.menuHide' : 'menu.menuShow') | translate }}"
            (click)="toggleMinimize($event)"
        >
            <span class="c-header-toggler-icon"></span>
        </button>
        <button
            class="c-header-toggler c-class-toggler mfs-3 d-md-down-none"
            type="button"
            responsive="true"
            title="{{ (sidebarShowLG ? 'menu.menuHide' : 'menu.menuShow') | translate }}"
            (click)="toggleSidebarLG($event)"
        >
            <span class="c-header-toggler-icon"></span>
        </button>
        <div class="alert alert-warn bg-gradient-warning" *ngIf="internaltest">INTERNÁ TESTOVACIA VERZIA</div>
        <div class="flex flex-1 justify-content-evenly align-self-center">
            <button
                class="p-2 feedback-button"
                (click)="showSentryFeedback()"
                pTooltip="{{ 'feedback.buttonTooltip' | translate }}"
                *ngIf="!admin"
            >
                <span data-pc-section="icon" class="pi pi-comments p-button-icon p-button-icon-left"></span>
                <span class="p-button-label feedback-text" aria-hidden="false" data-pc-section="label">{{
                    'feedback.bigButtonPrompt' | translate
                }}</span>
            </button>
        </div>
        <ul class="c-header-nav ml-auto mr-3">
            <li class="c-header-nav-item dropdown" dropdown>
                <a
                    class="c-header-nav-link"
                    role="button"
                    aria-haspopup="true"
                    aria-expanded="true"
                    dropdownToggle
                    (click)="(false)"
                    aria-controls="basic-link-dropdown"
                >
                    <div class="c-avatar">
                        <img class="c-avatar-img" src="assets/img/avatar.png" alt="Profil" />
                    </div>
                </a>
                <div class="dropdown-menu dropdown-menu-right pt-0" #dropdownMenu *dropdownMenu>
                    <a class="dropdown-item" [routerLink]="['/account/profile']">
                        <i class="c-icon mr-2 fas fa-id-card"></i>
                        {{ 'menu.profile' | translate }}
                    </a>

                    <a class="dropdown-item" (click)="logout($event)">
                        <i class="c-icon mr-2 fas fa-sign-out-alt"></i>
                        {{ 'menu.logout' | translate }}
                    </a>
                </div>
            </li>
        </ul>

        <ui-breadcrumbs remove-host [breadcrumbAction]="breadcrumbActionFromChild"></ui-breadcrumbs>
        <!-- Header content here -->
    </header>
    <div class="c-body">
        <main class="c-main flex">
            <div class="container-fluid">
                <router-outlet (activate)="currentComponent = $event"></router-outlet>
            </div>
        </main>
        <footer class="c-footer">
            <span><a href="https://slovgen.sk">SlovgenDB</a> &copy; {{ year }} Slovgen s.r.o.</span>
            <!-- <span class="ml-auto">Made by <a href="https://binarytale.com">BinaryTale</a></span> -->
            <!-- Footer content here -->
        </footer>
    </div>
</div>

<div class="c-sidebar-backdrop c-fade c-show" *ngIf="sidebarBackdrop" (click)="backdropClick($event)"></div>
