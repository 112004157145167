import { Component, Input, OnInit, OnChanges, Optional, Host, SkipSelf } from '@angular/core'
import { AbstractControlDirective, AbstractControl, ControlContainer } from '@angular/forms'
interface ErrorObj {
    msg: string
    params?: any
}
@Component({
    selector: 'ui-show-errors',
    template: `
        <div *ngIf="shouldShowErrors()" class="invalid-feedback" style="display: block">
            <span class="validation-message error {{ textClass }}" *ngFor="let error of listOfErrors()">{{
                error.msg | translate : error.params
            }}</span>
        </div>
    `,
})
export class ShowErrorsComponent implements OnInit {
    constructor(@Optional() @Host() @SkipSelf() parent: ControlContainer) {
        this.parent = parent
        // console.log(`Show error in form name: ${parent.name} obj ${parent} dir ${this.formDirective}`);
    }

    parent: ControlContainer
    @Input()
    private control: AbstractControlDirective | AbstractControl
    @Input()
    private controlName: string
    @Input()
    textClass = ''
    ngOnInit(): void {
        if (!this.control) {
            if (!this.controlName) {
                throw new Error(`app-show-errors inside form: ${this.parent.name} has no control set!`)
            } else {
                this.control = this.parent.control.get(this.controlName)
            }
        }
    }

    get formDirective(): any {
        return this.parent ? this.parent.formDirective : null
    }

    shouldShowErrors(): boolean {
        return this.control && this.control.errors && (this.control.dirty || this.control.touched)
    }

    listOfErrors(): ErrorObj[] {
        return Object.keys(this.control.errors).map((field) => this.getMessage(field, this.control.errors[field]))
    }

    private getMessage(type: string, params: any) {
        return {
            msg: `validation.${type}`,
            params,
        }
    }
}
