<div class="app flex-row align-items-center">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="clearfix">
                    <h1 class="float-left display-3 mr-4">403</h1>
                    <h4 class="pt-3">Oops! You are not authorized.</h4>
                    <p class="text-muted">You do not have permission required to view this site.</p>
                </div>

                <a
                    class="btn btn-primary"
                    title="Go back to home page"
                    [routerLink]="['/']"
                    routerLinkActive="router-link-active"
                    >Back Home</a
                >
                <a class="btn btn-secondary" title="Logout" (click)="logout()">Logout</a>
            </div>
        </div>
    </div>
</div>
