<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="mx-auto" [ngClass]="{ 'col-lg-6': hideInfo, 'col-lg-10': !hideInfo }">
                    <div class="card-group">
                        <div class="card p-2" style="flex: 2">
                            <div class="card-body">
                                <div class="alert alert-danger fade-in" role="alert" *ngIf="errorMessage">
                                    {{ errorMessage | translate }}
                                </div>
                                <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
                                    <h1>{{ 'login.signin' | translate }}</h1>
                                    <p class="text-muted">
                                        {{ 'login.prompt' | translate }}
                                    </p>
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="mi">person</i></span>
                                        </div>
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="{{ 'login.username' | translate }}"
                                            autocomplete="off"
                                            formControlName="username"
                                        />
                                    </div>
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="mi">lock</i></span>
                                        </div>
                                        <input
                                            #pwdInput
                                            type="password"
                                            class="form-control"
                                            placeholder="{{ 'login.password' | translate }}"
                                            autocomplete="current-password"
                                            formControlName="password"
                                        />
                                        <div class="input-group-append">
                                            <button class="btn btn-secondary" type="button" (click)="toggle(pwdInput)">
                                                <i
                                                    class="fas fa-eye{{
                                                        pwdInput.getAttribute('type') === 'password' ? '' : '-slash'
                                                    }}"
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div class="input-group mb-4">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text"><i class="mi">language</i></span>
                                        </div>
                                        <ng-select
                                            formControlName="language"
                                            title="{{ 'login.language' | translate }}"
                                            [searchable]="false"
                                            [clearable]="false"
                                            (change)="onLanguageChange($event)"
                                            style="flex: 1"
                                        >
                                            <ng-option value="sk"> Slovenčina </ng-option>
                                            <ng-option value="en"> English </ng-option>
                                        </ng-select>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <button
                                                class="btn btn-primary"
                                                type="submit"
                                                [disabled]="!loginForm.valid || loading"
                                            >
                                                <span
                                                    *ngIf="loading"
                                                    class="spinner-border spinner-border-sm mr-1"
                                                ></span>
                                                {{ 'login.signin' | translate }}
                                            </button>
                                        </div>
                                        <div class="col-6 text-right">
                                            <button
                                                [routerLink]="['/account/forgotpassword']"
                                                type="button"
                                                class="btn btn-link px-0"
                                            >
                                                {{ 'login.forgotPassword' | translate }}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="card text-white bg-primary py-4" *ngIf="!hideInfo">
                            <div class="card-body text-center">
                                <div>
                                    <h2>{{ 'login.dontHaveAnAccount' | translate }}</h2>
                                    <p>
                                        {{ 'login.registrationBenefits' | translate }}
                                    </p>
                                    <a
                                        [routerLink]="['/account/register']"
                                        role="button"
                                        class="btn btn-lg btn-outline-light mt-3"
                                    >
                                        {{ 'login.register' | translate }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
