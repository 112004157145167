<ul class="c-sidebar-nav">
    <ng-template ngFor let-item [ngForOf]="menuItems">
        <li *ngIf="!item.children && !item.title" class="c-sidebar-nav-item">
            <a
                class="c-sidebar-nav-link"
                (click)="onAction($event)"
                [routerLink]="[item.route]"
                routerLinkActive="c-active"
                [routerLinkActiveOptions]="{ exact: item.routeExactMatch }"
                *ngIf="item.route"
            >
                <i class="c-sidebar-nav-icon {{ item.icon }}"></i>
                {{ item.name | translate }}
            </a>
            <a
                class="c-sidebar-nav-link"
                (click)="onAction($event)"
                [href]="[item.url]"
                routerLinkActive="c-active"
                *ngIf="item.url"
            >
                <i class="c-sidebar-nav-icon {{ item.icon }}"></i>
                {{ item.name | translate }}
            </a>
        </li>
        <li class="c-sidebar-nav-dropdown" *ngIf="item.children" [id]="item.name">
            <a
                class="c-sidebar-nav-dropdown-toggle"
                (click)="toggleDropdown(item.name)"
                [routerLink]="[item.route]"
                routerLinkActive="c-active"
                [routerLinkActiveOptions]="{ exact: false }"
            >
                <i class="c-sidebar-nav-icon {{ item.icon }}"></i> {{ item.name | translate }}</a
            >
            <ul class="c-sidebar-nav-dropdown-items pl-2">
                <li class="c-sidebar-nav-item" *ngFor="let child of item.children">
                    <a
                        class="c-sidebar-nav-link"
                        (click)="onAction($event)"
                        [routerLink]="[child.route]"
                        routerLinkActive="c-active"
                        [routerLinkActiveOptions]="{ exact: true }"
                        *ngIf="item.route"
                    >
                        <i class="c-sidebar-nav-icon {{ child.icon }}"></i>
                        {{ child.name | translate }}
                    </a>
                </li>
            </ul>
        </li>

        <ng-container *ngIf="item.title">
            <li class="c-sidebar-nav-divider"></li>
            <li class="c-sidebar-nav-title">{{ item.name | translate }}</li>
        </ng-container>
    </ng-template>
</ul>
