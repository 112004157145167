import { Component, OnInit, Input } from '@angular/core'
import { Environment } from '../../config/environment'

@Component({
    host: { ngNoHost: '' },
    selector: 'ui-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    @Input()
    menuItems
    private _sidebarShowLG: boolean
    private _sidebarShowMobile: boolean

    @Input()
    set sidebarShowLG(sidebarShowLG: boolean) {
        // console.log('Sidebar toggle ' + sidebarShowLG)
        this._sidebarShowLG = sidebarShowLG
    }
    get sidebarShowLG() {
        return this._sidebarShowLG
    }
    @Input()
    set sidebarShowMobile(sidebarShowMobile: boolean) {
        // console.log('Sidebar toggle ' + sidebarShowMobile)
        this._sidebarShowMobile = sidebarShowMobile
    }
    get sidebarShowMobile() {
        return this._sidebarShowMobile
    }

    version: string
    constructor(private environment: Environment) {}

    ngOnInit() {
        this.version = this.environment.version
    }
}
