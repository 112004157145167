import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import { finalize } from 'rxjs/operators'
import { AuthService } from '../services/auth.service'

@Component({
    templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit {
    loginForm!: FormGroup
    error?: boolean
    errorMessage?: string
    loading = false
    returnUrl!: string
    hideInfo = false

    constructor(
        private authService: AuthService,
        private formBuilder: FormBuilder,
        private router: Router,
        private route: ActivatedRoute,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            username: [null, Validators.required],
            password: [null, Validators.required],
            language: [this.translate.currentLang],
        })

        // get return url from route parameters or default to '/'
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/'
        this.hideInfo = this.route.snapshot.data['hideInfo']
    }

    toggle(input: HTMLElement) {
        if (input.getAttribute('type') === 'text') {
            input.setAttribute('type', 'password')
        } else {
            input.setAttribute('type', 'text')
        }
    }

    onLanguageChange(value: string) {
        this.translate.use(value.toLowerCase())
    }

    get f() {
        return this.loginForm.controls
    }

    login() {
        this.error = false
        this.loading = true
        this.authService
            .login({
                ...this.loginForm.value,
            })
            .pipe(
                finalize(() => {
                    this.loading = false
                })
            )
            .subscribe({
                next: (success) => {
                    if (success) {
                        if (this.authService.hasRoleAssigned(['admin']) && !location.pathname.startsWith('/admin')) {
                            location.href = '/admin'
                        } else {
                            this.router.navigateByUrl(this.returnUrl)
                            this.error = false
                        }
                    } else {
                        this.error = true
                    }
                },
                error: (err) => {
                    switch (err.status) {
                        case 401: {
                            this.errorMessage = 'login.invalidCredentials'
                            break
                        }
                        case 417: {
                            this.errorMessage = 'login.accountNotActivated'
                            break
                        }
                        default: {
                            this.errorMessage = 'login.authProblem'
                            break
                        }
                    }
                    this.error = true
                },
            })
    }
}
