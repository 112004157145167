import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { ButtonComponent } from './components/button.component'
import { ConfirmActionDirective } from './components/confirm-action.directive'
import { FormErrorsComponent } from './components/form-errors.component'
import { LoadingIndicatorComponent } from './components/loading-indicator.component'
import { ShowErrorsComponent } from './components/show-errors.component'
import { LocalizedDatePipe } from './pipes/localized-date.pipe'
import { CacheService } from './services/cache.service'
import { MissingI18nService } from './services/missing-i18n.service'

import { RouterModule } from '@angular/router'
import { NgSelectModule } from '@ng-select/ng-select'
import { ToastrModule } from 'ngx-toastr'
import { P403Component } from './components/403.component'
import { P404Component } from './components/404.component'
import { P500Component } from './components/500.component'
import { BreadcrumbsComponent } from './components/breadcrumbs.component'
import { DefaultLayoutComponent } from './components/default-layout'
import { InlineProfileComponent } from './components/inline-profile/inline-profile.component'
import { LoginComponent } from './components/login.component'
import { MenuComponent } from './components/menu/menu.component'
import { PageComponent } from './components/page.component'
import { SidebarComponent } from './components/sidebar/sidebar.component'

import { RemoveHost } from './components/remove-host.component'
import { SortPipe } from './pipes/sort.pipe'
import { TokenInterceptor } from './services/token-interceptor'

import { BsDropdownModule } from 'ngx-bootstrap/dropdown'
import { ButtonModule } from 'primeng/button'
import { DialogService } from 'primeng/dynamicdialog'
import { TooltipModule } from 'primeng/tooltip'
import { BlockableComponent } from './components/blockable.component'
import { CategorySelectComponent } from './components/category-select/category-select.component'
import { LoginDialogComponent } from './components/login-dialog/login-dialog.component'
import { NoteComponent } from './components/note/note.component'
import { CalendarDefaultsDirective } from './directives/primeng-cal-locale.directive'
import { PrintDirective } from './directives/print.directive'
import { EmptyPipe } from './pipes/empty.pipe'
import { FormatLabelPipe } from './pipes/format-label.pipe'

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json')
}

export function initializeAppCache(cacheService: CacheService) {
    return (): Promise<any> => {
        return cacheService.init()
    }
}
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgSelectModule,
        ButtonModule,
        TooltipModule,
        RouterModule.forChild([]),
        ToastrModule.forRoot(),
        BsDropdownModule.forRoot(),
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
            missingTranslationHandler: {
                provide: MissingTranslationHandler,
                useClass: MissingI18nService,
            },
            isolate: false,
        }),
        // ContribNgNoHostModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ToastrModule,
        NgSelectModule,
        BsDropdownModule,

        LoginComponent,
        ShowErrorsComponent,
        FormErrorsComponent,
        ButtonComponent,
        LoadingIndicatorComponent,
        ConfirmActionDirective,
        CalendarDefaultsDirective,
        PrintDirective,
        LocalizedDatePipe,
        SortPipe,
        FormatLabelPipe,
        EmptyPipe,
        RemoveHost,
        NoteComponent,

        DefaultLayoutComponent,
        P404Component,
        P403Component,
        P500Component,
        PageComponent,
        LoginDialogComponent,
        BlockableComponent,
    ],
    declarations: [
        LoginComponent,
        ShowErrorsComponent,
        FormErrorsComponent,
        ButtonComponent,
        LoadingIndicatorComponent,
        ConfirmActionDirective,
        LocalizedDatePipe,
        SortPipe,
        FormatLabelPipe,
        EmptyPipe,
        DefaultLayoutComponent,
        SidebarComponent,
        MenuComponent,
        InlineProfileComponent,
        BreadcrumbsComponent,
        CalendarDefaultsDirective,
        P404Component,
        P403Component,
        P500Component,
        PageComponent,
        RemoveHost,
        PrintDirective,
        CategorySelectComponent,
        NoteComponent,
        LoginDialogComponent,
        BlockableComponent,
    ],
    providers: [
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: initializeAppCache,
        //     deps: [CacheService],
        //     multi: true,
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        DialogService,
    ],
})
export class SharedModule {}
