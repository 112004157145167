/**
 * SlovgenDB API
 * Open API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum OrderStatus {
    NEW = 'NEW',
    WAITING_FOR_SAMPLES = 'WAITING_FOR_SAMPLES',
    PROCESSING = 'PROCESSING',
    RESULTS_READY = 'RESULTS_READY',
    WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
    COMPLETED = 'COMPLETED',
    PAID = 'PAID',
    STORNO = 'STORNO'
}

