import { HttpContext, HttpContextToken } from '@angular/common/http'
import { AbstractControl, FormArray, FormControl, FormGroup, ɵElement } from '@angular/forms'

// export type ControlsOf<T extends Record<string, any>> = {
//     [K in keyof T]: T[K] extends Record<any, any> ? FormGroup<ControlsOf<T[K]>> : FormControl<T[K]>
// }
type NonUndefined<T> = T extends undefined ? never : T

// export type ControlsOfE<T extends Record<string, any>> = {
//     [K in keyof T]?: NonUndefined<T[K]> extends Record<any, any>
//         ? NonUndefined<T[K]> extends (infer U)[]
//             ? NonUndefined<U> extends Record<any, any>
//                 ? FormArray<FormGroup<ControlsOfE<U>>>
//                 : FormArray<FormControl<U>>
//             : FormGroup<ControlsOfE<T[K]>>
//         : FormControl<T[K]>
// }
// export type ControlsOf<T> = {
//     [K in keyof T]: T[K] extends Array<infer U>
//         ? FormArray<FormControl<U>>
//         : T[K] extends Array<infer U> | undefined
//         ? FormArray<FormControl<U>>
//         : T[K] extends ReadonlyArray<infer U>
//         ? FormArray<FormControl<U>>
//         : T[K] extends ReadonlyArray<infer U> | undefined
//         ? FormArray<FormControl<U>>
//         : ɵElement<T[K], never> | undefined
// }

export type ControlsOf<T extends Record<string, any>> = {
    [K in keyof T]: NonUndefined<T[K]> extends (infer R extends AbstractControl<any, any>)[]
        ? FormArray<R>
        : NonUndefined<T[K]> extends Date
        ? FormControl<T[K] | null>
        : NonUndefined<T[K]> extends Array<string>
        ? FormControl<T[K]>
        : NonUndefined<T[K]> extends Record<any, any>
        ? FormGroup<ControlsOf<T[K]>>
        : FormControl<T[K] | null>
}

export const NO_DEFAULT_ERR_HANDLER_CONTEXT = new HttpContextToken<boolean>(() => false)

export function withoutDefaultErr<T, R>(
    fn: (
        arg0: T,
        observe?: 'body',
        reportProgress?: boolean,
        options?: { httpHeaderAccept?: 'application/json'; context?: HttpContext }
    ) => R,
    arg: T
): R {
    return fn(arg, undefined, false, {
        context: new HttpContext().set(NO_DEFAULT_ERR_HANDLER_CONTEXT, true),
    })
}
