import { APP_INITIALIZER } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'
import * as Sentry from '@sentry/angular-ivy'
import { PrimeNGConfig } from 'primeng/api'
import { filter, Observable, of, OperatorFunction, switchMap, take, tap } from 'rxjs'
import { User } from '../../models/user.model'
import { AuthService } from './auth.service'
import { CacheService } from './cache.service'
import { ConfigService } from './config.service'

const appInitializer = {
    provide: APP_INITIALIZER,
    multi: true,
    deps: [CacheService, TranslateService, AuthService, PrimeNGConfig, ConfigService],
    useFactory: appInitializerFn,
}
function appInitializerFn(
    cache: CacheService,
    translate: TranslateService,
    authService: AuthService,
    config: PrimeNGConfig,
    appConfig: ConfigService
): () => Observable<any> {
    let userBrowserLang = translate.getBrowserLang()
    userBrowserLang = userBrowserLang?.toLowerCase()
    userBrowserLang = userBrowserLang?.match(/cs|cz/) ? 'sk' : userBrowserLang
    translate.addLangs(['en', 'sk'])
    const lang = document.createAttribute('lang')

    // this language will be used as a fallback when a translation isn't found in the current language
    // translate.setDefaultLang('en')
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    appConfig.init()
    return () =>
        authService.currentUser$.pipe(
            take(1),
            switchMap((u) => {
                console.log(`Auth is : ${u}`)
                let lang = userBrowserLang
                if (u) {
                    lang = u.locale
                }
                lang = lang?.match(/en|sk/) ? lang : 'en'
                console.log(`🌍 Language: ${lang}, browser lang: ${userBrowserLang} user lang ${u?.locale}`)
                return translate.use(lang)
            }),

            switchMap(() => {
                lang.value = translate.currentLang
                return authService.isLoggedIn() ? cache.init() : of(true)
            }),
            tap(() => {
                console.log(`🚀 App init done`)
                userLangChangeListener(cache, translate, authService, config)
            })
        )
    // .pipe(tap((val) => console.log(`Whoa ${val}`)))
}

function userLangChangeListener(
    cache: CacheService,
    translate: TranslateService,
    authService: AuthService,
    config: PrimeNGConfig
) {
    authService.currentUser$
        .pipe(filter((v) => v !== null) as OperatorFunction<User | null, User>)
        .subscribe((user) => {
            console.log(`🌍 Listener: User change obs, switch lang to ${user.locale.toLowerCase()}`)
            if (user) {
                let newLocale = user.locale.toLowerCase()
                if (newLocale === 'cz') {
                    newLocale = 'sk'
                }
                Sentry.setUser({ username: user.username })
                Sentry.setContext('state', {
                    lang: newLocale,
                    customerId: user.customerId,
                })
                translate.use(newLocale).subscribe(() => {
                    translate.get('primeng').subscribe((res) => config.setTranslation(res))
                })
            }
        })
}

export { appInitializer }
