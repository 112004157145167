import { Component } from '@angular/core'
import { AuthService } from '../services/auth.service'
import { Router } from '@angular/router'

@Component({
    templateUrl: '403.component.html',
})
export class P403Component {
    constructor(private authService: AuthService, private router: Router) {}
    logout() {
        this.authService.logout()
    }
}
