import { ValidatorFn, AbstractControl, UntypedFormArray } from '@angular/forms'

// Array Validators
export class ArrayValidators {
    // max length
    public static maxLength(max: number): ValidatorFn | any {
        return (control: AbstractControl[]) => {
            if (!(control instanceof UntypedFormArray)) return
            return control.length > max ? { maxArrayLength: true } : null
        }
    }

    // min length
    public static minLength(min: number): ValidatorFn | any {
        return (control: AbstractControl[]) => {
            if (!(control instanceof UntypedFormArray)) return
            return control.length < min ? { minArrayLength: true } : null
        }
    }
}
