import { Component, OnInit } from '@angular/core'
import { AuthService } from '../../services/auth.service'
import { TranslateService } from '@ngx-translate/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { DynamicDialogRef } from 'primeng/dynamicdialog'
import { finalize } from 'rxjs'

@Component({
    template: `
        <div class="p-2">
            <div class="w-full">
                <div class="alert alert-danger fade-in" role="alert" *ngIf="error">
                    {{ 'login.authProblem' | translate }}
                </div>
                <form [formGroup]="loginForm" (ngSubmit)="login()" novalidate>
                    <h1>{{ 'login.sessionTimeout' | translate }}</h1>
                    <p class="text-muted">
                        {{ 'login.promptAfterTimeout' | translate }}
                    </p>
                    <div class="input-group mb-3">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="mi">person</i></span>
                        </div>
                        <input
                            type="text"
                            class="form-control"
                            placeholder="{{ 'login.username' | translate }}"
                            autocomplete="off"
                            formControlName="username"
                        />
                    </div>
                    <div class="input-group mb-4">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="mi">lock</i></span>
                        </div>
                        <input
                            #pwdInput
                            type="password"
                            class="form-control"
                            placeholder="{{ 'login.password' | translate }}"
                            autocomplete="current-password"
                            formControlName="password"
                        />
                        <div class="input-group-append">
                            <button class="btn btn-secondary" type="button" (click)="toggle(pwdInput)">
                                <i
                                    class="fas fa-eye{{ pwdInput.getAttribute('type') === 'password' ? '' : '-slash' }}"
                                ></i>
                            </button>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-6">
                            <button pButton [loading]="loading" type="submit" [disabled]="!loginForm.valid || loading">
                                {{ 'login.signin' | translate }}
                            </button>
                        </div>
                        <div class="col-6 text-right">
                            <button (click)="logoutAndBack()" type="button" class="btn btn-link px-0">
                                {{ 'login.logoutAndBack' | translate }}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    `,
})
export class LoginDialogComponent implements OnInit {
    error?: boolean
    loading = false
    returnUrl?: string
    hideInfo = false
    loginForm: FormGroup<{
        username: FormControl<string>
        password: FormControl<string>
        language: FormControl<string>
    }>

    constructor(
        private authService: AuthService,
        private translate: TranslateService,
        private fb: FormBuilder,
        private ref: DynamicDialogRef
    ) {
        this.loginForm = this.fb.nonNullable.group({
            username: ['', Validators.required],
            password: ['', Validators.required],
            language: [this.translate.currentLang],
        })
    }

    ngOnInit() {}

    toggle(input: HTMLElement) {
        if (input.getAttribute('type') === 'text') {
            input.setAttribute('type', 'password')
        } else {
            input.setAttribute('type', 'text')
        }
    }

    logoutAndBack() {
        this.authService.logout()
    }

    login() {
        this.error = false
        this.loading = true
        this.authService
            .login({
                username: this.loginForm.value.username!,
                password: this.loginForm.value.password!,
            })
            .pipe(
                finalize(() => {
                    this.loading = false
                })
            )
            .subscribe({
                next: (success) => {
                    if (success) {
                        this.error = false
                        this.ref.close(true)
                    } else {
                        this.error = true
                    }
                },
                error: (err) => {
                    this.error = true
                },
            })
    }
}
