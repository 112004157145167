import { Injectable } from '@angular/core'
import { CacheAPIService, CacheData, AnalysisListItem, ExtractionMethodListItem } from '@resultsgate-ui/api'
import { SelectItem } from '@resultsgate-ui/shared'

@Injectable({
    providedIn: 'root',
})
export class CacheService {
    rootCategoriesSelectItems: SelectItem<number>[] = []
    categoriesMap: { [key: string]: SelectItem<number>[] } = {}
    allCategoriesMap: Map<number, string> = new Map()

    analysisMap: Map<number, SelectItem<number>> = new Map()
    extractionMethodMap: { [key: string]: SelectItem<number> } = {}
    extractionMethodSelectItems!: SelectItem<number>[]
    extractionMethodSelectItemsForKit!: SelectItem<number>[]
    analysisSelectItems: SelectItem<number>[] = []
    analysisSelectItemsInclDisabled: SelectItem<number>[] = []
    analysisCache: Map<number, AnalysisListItem> = new Map()
    extMethodCache: Map<number, ExtractionMethodListItem> = new Map()

    resolutionDone = false

    constructor(private api: CacheAPIService) {
        console.log(`cache service constructor`)
    }

    public init() {
        return this.reload()
    }

    public reload() {
        return this.api
            .getCacheData()
            .toPromise()
            .then((data) => {
                console.log('cache init resolve true')
                this.createSelectItems(data!)
                this.resolutionDone = true
                return true
            })
            .catch((e) => {
                console.log('cache init resolve false - error')
                return false
            })
    }
    createSelectItems(data: CacheData) {
        this.rootCategoriesSelectItems = []
        this.categoriesMap = {}
        this.allCategoriesMap = new Map()

        this.analysisMap = new Map()
        this.extractionMethodMap = {}
        this.extractionMethodSelectItems = []
        this.analysisSelectItems = []
        this.analysisSelectItemsInclDisabled = []
        this.analysisCache = new Map()
        this.extMethodCache = new Map()

        data.categoriesCache.reduce((agg, current) => {
            if (!current.parentId) {
                agg[current.id] = []
                this.rootCategoriesSelectItems.push({ id: current.id, label: current.name })
            }
            agg[current.parentId!] = agg[current.parentId!] || []
            agg[current.parentId!].push({ id: current.id, label: current.name, group: '' + current.parentId })
            this.allCategoriesMap.set(current.id, current.name)
            return agg
        }, this.categoriesMap)
        Object.keys(this.categoriesMap).forEach((k) =>
            this.categoriesMap[k].sort((a, b) => (a.label || '').localeCompare(b.label || ''))
        )

        this.extractionMethodSelectItems = data.extractionMethodCache.map((em) => {
            const selItem = {
                id: em.id,
                label: em.name,
                data: em.categories,
            }
            this.extractionMethodMap[em.id] = selItem
            return selItem
        })
        this.extractionMethodSelectItemsForKit = data.extractionMethodCache
            .filter((i) => i.showOnKitSelection)
            .map((em) => {
                const selItem = {
                    id: em.id,
                    label: em.name,
                    data: em.categories,
                }
                return selItem
            })

        this.analysisSelectItems = data.analysisCache
            .filter((al) => !al.disabled)
            .map((al) => {
                const selItem = {
                    id: al.id,
                    label: `${al.name} ${al.description || ''}`,
                }
                this.analysisMap.set(al.id, selItem)
                return selItem
            })
            .sort((a, b) => a.label.toUpperCase().localeCompare(b.label.toUpperCase()))
        // hm what is this
        data.analysisCache.forEach((al) => {
            this.analysisSelectItemsInclDisabled.push({
                id: al.id,
                label: al.name,
            })
            this.analysisCache.set(al.id, al)
        })
        this.analysisSelectItemsInclDisabled.sort((a, b) => a.label.toUpperCase().localeCompare(b.label.toUpperCase()))
    }
}
