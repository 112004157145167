import { Directive, Host, Optional, Self } from '@angular/core'
import { Calendar } from 'primeng/calendar'
import { AuthService } from '../services/auth.service'

const SK_LOCALE = {
    firstDayOfWeek: 1,
    dayNames: ['Pondelok', 'Utorok', 'Streda', 'Štvrtok', 'Piatok', 'Sobota', 'Nedeľa'],
    dayNamesShort: ['Po', 'Ut', 'St', 'Št', 'Pi', 'So', 'Ne'],
    dayNamesMin: ['P', 'U', 'S', 'Š', 'P', 'S', 'N'],
    monthNames: [
        'leden',
        'únor',
        'březen',
        'duben',
        'květen',
        'červen',
        'červenec',
        'srpen',
        'září',
        'říjen',
        'listopad',
        'prosinec',
    ],
    monthNamesShort: ['led', 'úno', 'bře', 'dub', 'kvě', 'čvn', 'čvc', 'srp', 'zář', 'říj', 'lis', 'pro'],
    today: 'Dnes',
    clear: 'Zmazať',
}
const CZ_LOCALE = {
    firstDayOfWeek: 1,
    dayNames: ['pondělí', 'úterý', 'středa', 'čtvrtek', 'pátek', 'sobota', 'neděle'],
    dayNamesShort: ['Po', 'Út', 'St', 'Čt', 'Pá', 'So', 'Ne'],
    dayNamesMin: ['P', 'U', 'S', 'Š', 'Č', 'S', 'N'],
    monthNames: [
        'január',
        'február',
        'marec',
        'apríl',
        'máj',
        'jún',
        'júl',
        'August',
        'semptember',
        'október',
        'november',
        'december',
    ],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'Máj', 'Jún', 'Júl', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
    today: 'Dnes',
    clear: 'Zmazať',
}

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const currentYear = new Date().getFullYear()
const dateRangeStart = currentYear - 20

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'p-calendar',
})
export class CalendarDefaultsDirective {
    constructor(@Host() @Self() @Optional() public host: Calendar, private auth: AuthService) {
        host.dateFormat = 'dd.mm.yy'
        host.firstDayOfWeek = 1
        host.inputStyleClass = 'form-value'
        host.showButtonBar = true
        host.showIcon = true
        host.monthNavigator = true
        host.yearNavigator = true
        host.yearRange = `${dateRangeStart}:${currentYear}`
        if (isMobile) {
            host.touchUI = true
            host.readonlyInput = true
        }
        // [touchUI]="true" [readonlyInput]="true"

        const _utcRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}Z?/
        this.host.writeValue = function (value) {
            this.value = value
            if (this.value && typeof this.value === 'string') {
                this.value = _utcRegex.test(value) ? new Date(value) : this.parseValueFromString(value)
            }
            this.updateInputfield()
            this.updateUI()
            this.cd.markForCheck()
        }

        // this.auth.currentUser$.subscribe((u) => {
        //     if (u) {
        //         switch (u.locale.toLowerCase()) {
        //             case 'sk':
        //                 host.locale = SK_LOCALE
        //                 host.dateFormat = 'dd.mm.yyyy'
        //                 break
        //             case 'cz':
        //             case 'cs':
        //                 host.locale = CZ_LOCALE
        //                 host.dateFormat = 'dd.mm.yyyy'
        //                 break
        //             default:
        //                 host.locale = host._locale
        //                 break
        //         }
        //     }
        // })
    }
}
