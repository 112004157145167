import { Router, RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree } from '@angular/router'
import { AuthService } from './auth.service'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { CacheService } from './cache.service'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard  {
    constructor(private authService: AuthService, private router: Router, private cacheService: CacheService) {}

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.checkAccess(childRoute.data.roles, state.url)
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.checkAccess(route.data.roles, state.url)
    }

    checkAccess(roles: any, returnUrl: string): boolean | Promise<boolean> {
        console.log(`Auth guard: ${returnUrl} for ${roles}`)
        if (!roles) {
            return true
        }
        if (!this.authService.isLoggedIn()) {
            this.router.navigate(['login'], { queryParams: { returnUrl } })
            return false
        }
        if (!this.authService.hasRoleAssigned(roles)) {
            this.router.navigate(['/403'], { queryParams: { source: returnUrl } })
            return false
        }
        if (!this.cacheService.resolutionDone) {
            console.log('cache not initialized on auth guard, rerun')
            return this.cacheService.init()
        }
        return this.authService.isLoggedIn()
    }
}
