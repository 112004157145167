import { Component, OnInit, Input, TemplateRef } from '@angular/core'
import { ActivatedRoute, Router, NavigationEnd, PRIMARY_OUTLET } from '@angular/router'
import { filter } from 'rxjs/operators'
import { IBreadcrumb } from '../../models/breadcrumb.model'

const ROUTE_DATA_BREADCRUMB = 'breadcrumb'

@Component({
    host: { ngNoHost: '' },
    selector: 'ui-breadcrumbs',
    template: `
        <nav aria-label="breadcrumb" class="c-subheader justify-content-between px-3 shadow-sm">
            <ol class="breadcrumb border-0 m-0 px-0 px-md-3">
                <li class="breadcrumb-item">
                    <a routerLink="/">Home</a>
                </li>
                <li class="breadcrumb-item" routerLinkActive="active" *ngFor="let breadcrumb of breadcrumbs">
                    <a [routerLink]="[breadcrumb.url]">{{ breadcrumb.label | translate : breadcrumb.params }}</a>
                </li>
            </ol>
            <div class="c-subheader-nav mfe-2" *ngIf="breadcrumbAction">
                <ng-container *ngTemplateOutlet="breadcrumbAction"></ng-container>
            </div>
        </nav>
    `,
    styles: [],
})
export class BreadcrumbsComponent implements OnInit {
    @Input() breadcrumbAction?: TemplateRef<any>
    public breadcrumbs: IBreadcrumb[]

    constructor(private activatedRoute: ActivatedRoute, private router: Router) {
        this.breadcrumbs = []
    }

    ngOnInit() {
        const root: ActivatedRoute = this.activatedRoute.root
        this.breadcrumbs = this.getBreadcrumbs(root)
        // subscribe to the NavigationEnd event
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event) => {
            // set breadcrumbs
            this.breadcrumbs = this.getBreadcrumbs(root)
        })
    }

    private getBreadcrumbs(route: ActivatedRoute, url: string = '', breadcrumbs: IBreadcrumb[] = []): IBreadcrumb[] {
        // get the child routes
        const children: ActivatedRoute[] = route.children

        // return if there are no more children
        if (children.length === 0) {
            return breadcrumbs
        }

        // iterate over each children
        for (const child of children) {
            // verify primary route
            if (child.outlet !== PRIMARY_OUTLET) {
                continue
            }

            // verify the custom data property "breadcrumb" is specified on the route
            if (!child.snapshot.data.hasOwnProperty(ROUTE_DATA_BREADCRUMB)) {
                return this.getBreadcrumbs(child, url, breadcrumbs)
            }

            // get the route's URL segment
            const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/')

            // append route URL to URL
            url += `/${routeURL}`

            // add breadcrumb
            const breadcrumb: IBreadcrumb = {
                label: child.snapshot.data[ROUTE_DATA_BREADCRUMB],
                params: child.snapshot.params,
                url,
            }
            if (routeURL !== '') {
                breadcrumbs.push(breadcrumb)
            }

            // recursive
            return this.getBreadcrumbs(child, url, breadcrumbs)
        }

        // we should never get here, but just in case
        return breadcrumbs
    }
}
