import { ɵComponentDef as ComponentDef, ɵDirectiveDef as DirectiveDef, InjectableType } from '@angular/core'

import 'reflect-metadata'

const store: { [key: string]: { [key: string]: any } } = {}
window['store'] = store

export function State(value?: string): PropertyDecorator {
    return (target: any, propertyKey: string | symbol) => {
        // console.log(`Deco target: ${target.constructor.name}, propertyKey: ${String(propertyKey)}`)

        // console.log(`patched already? ${isPatchedAlready}`)
        if (!isDecorated(target)) {
            addAttrStore(target)
            markAsDecorated(target)
        }
        target['_stateStoreAttrs'].push(String(propertyKey))
    }
}

function addAttrStore(target: any) {
    target['_stateStoreAttrs'] = []
}

export function Stateful(options: StatefulOptions): ClassDecorator {
    return (target: any) => {
        // console.log(
        //     `decorating...${options.name} attr ${target['_stateStoreAttrs']} protO ${target.prototype['_stateStoreAttrs']}`
        // )
        decorate(target.prototype, options.name)
    }
}

function decorate(target: any, name: string) {
    const originalNgOnDestroy = target.ngOnDestroy
    const originalNgOnInit = target.ngOnInit

    // console.log(`Natus test ${originalNgOnDestroy} constr ${typeof target}`)
    target.ngOnDestroy = function () {
        // console.log(`Calling patched ngondestroy`)
        if (this._saveState) {
            this._saveState()
        } else {
            console.log(` NO STATE STORE METHOD`)
        }
        for (let prop in this) {
            const property = this[prop]
            // console.log(`& prop ${prop}`)
            if (property && typeof property.unsubscribe === 'function') {
                property.unsubscribe()
            }
        }
        originalNgOnDestroy && typeof originalNgOnDestroy === 'function' && originalNgOnDestroy.apply(this, arguments)
    }

    target.ngOnInit = function () {
        // console.log(`Calling patched ngOnInit`)
        if (this._restoreState) {
            this._restoreState()
        } else {
            console.log(` NO STATE RESTORE METHOD`)
        }

        originalNgOnInit && typeof originalNgOnInit === 'function' && originalNgOnInit.apply(this, arguments)
    }
    //rest
    if (!target['_stateStoreAttrs']) {
        target['_stateStoreAttrs'] = []
    }
    target['_stateStoreValues'] = {}

    target['_saveState'] = function () {
        // console.log(`** Doing save state of: ${clazzName}`)
        target['_stateStoreAttrs'].forEach((storeProperty) => {
            store[name + '#' + storeProperty] = this[storeProperty]
            // console.log(`   > ${storeProperty} - ${this[storeProperty]}`)
            // target['_stateStoreValues'][storeProperty] = this[storeProperty]
        })
        // console.log(`**Store state after(local): ${JSON.stringify(target['_stateStoreValues'])}`)
    }

    target['_restoreState'] = function () {
        // console.log(`** Doing restore state of: ${clazzName} thuis ${this} vs target ${target}`)
        target['_stateStoreAttrs'].forEach((storeProperty) => {
            this[storeProperty] = store[name + '#' + storeProperty]
        })
    }
}

export interface StatefulOptions {
    name: string
}

/**
 * Applied to definitions and informs that class is decorated
 */
const DECORATOR_APPLIED: unique symbol = Symbol('__stateStore')

function isDecorated(target: any) {
    return target[DECORATOR_APPLIED]
}
function markAsDecorated(providerOrDef: InjectableType<unknown> | DirectiveDef<unknown> | ComponentDef<unknown>): void {
    ;(providerOrDef as any)[DECORATOR_APPLIED] = true
}
