import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core'

@Component({
    selector: 'ui-loading-indicator',
    template: `
        <ng-container *ngIf="show">
            <div class="loading-indicator">Loading...</div>
        </ng-container>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent implements OnInit {
    @Input()
    show: boolean
    constructor() {}

    ngOnInit(): void {}
}
