import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from '@angular/core'
import { trigger, transition, style, animate } from '@angular/animations'

@Component({
  selector: 'ui-button',
  template: `
        <button
            type="{{ type }}"
            [disabled]="disabled || loading"
            class="btn {{ btnClass }}"
            (click)="onButtonClick($event)"
            [ngClass]="{ loading: loading }"
        >
            <span
                class="spinner-border spinner-border-sm loading-indicator"
                role="status"
                aria-hidden="true"
                [@inOutAnimation]
                (click)="onButtonClick($event)"
            ></span>
            <!-- ngif="loading" -->
            <i class="mi" *ngIf="icon">{{ icon }} </i>
            <span class="label"><ng-content></ng-content></span>
        </button>
    `,
  animations: [
    trigger('inOutAnimation', [
      // transition(':enter', [style({ opacity: 1 }), animate('.3s ease-out', style({ opacity: 1 }))]),
      // transition(':leave', [style({ opacity: 1 }), animate('.3s ease-in', style({ opacity: 0.1 }))]),
    ]),
  ],

  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @HostBinding('style.pointer-events') pointerEvents = 'none'
  @Input()
  icon: string
  @Input()
  type = 'button'
  @Input()
  loading: boolean
  @Input()
  btnClass = 'btn-primary'
  @Input()
  disabled: boolean
  // tslint:disable-next-line: no-output-native
  @Output() public click: EventEmitter<MouseEvent> = new EventEmitter()

  @Output() public btnClick: EventEmitter<MouseEvent> = new EventEmitter()
  constructor() { }

  ngOnInit() { }

  onButtonClick(event: MouseEvent) {
    if (this.disabled) {
      event.stopPropagation()
      event.preventDefault()
      return
    }
    event.stopPropagation()
    this.btnClick.emit()
    this.click.emit(event)
  }
}
