import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AdminAPIService } from './api/admin.service';
import { AnalysisAPIService } from './api/analysis.service';
import { AuthAPIService } from './api/auth.service';
import { CacheAPIService } from './api/cache.service';
import { CalendarEventsAPIService } from './api/calendarEvents.service';
import { CategoriesAPIService } from './api/categories.service';
import { CustomersAPIService } from './api/customers.service';
import { DashboardAPIService } from './api/dashboard.service';
import { EmailRequestsAPIService } from './api/emailRequests.service';
import { ExtractionMethodsAPIService } from './api/extractionMethods.service';
import { FilesAPIService } from './api/files.service';
import { ImportControllerAPIService } from './api/importController.service';
import { OrderDraftsAPIService } from './api/orderDrafts.service';
import { OrdersAPIService } from './api/orders.service';
import { ProtocolParserAPIService } from './api/protocolParser.service';
import { ReportsAPIService } from './api/reports.service';
import { SamplesAPIService } from './api/samples.service';
import { SamplingOrdersAPIService } from './api/samplingOrders.service';
import { SurveysAPIService } from './api/surveys.service';
import { VerificationAPIService } from './api/verification.service';
import { VouchersAPIService } from './api/vouchers.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
