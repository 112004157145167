import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core'
import { Injectable } from '@angular/core'

@Injectable()
export class MissingI18nService implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        console.error(`missing i18n ${params.key}`)
        return params.key
    }

    constructor() {}
}
