import { Directive, HostListener, Input } from '@angular/core'
import { Environment } from '../config/environment'

@Directive({
    selector: '[print]',
})
export class PrintDirective {
    public _printStyle = []

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @Input() printSectionId: string

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @Input() printTitle: string

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @Input() useExistingCss = false

    /**
     * A delay in milliseconds to force the print dialog to wait before opened. Default: 0
     *
     * @memberof NgxPrintDirective
     */
    @Input() printDelay: number = 100

    /**
     *
     *
     * @memberof NgxPrintDirective
     */
    @Input()
    set printStyle(values: { [key: string]: { [key: string]: string } }) {
        for (let key in values) {
            if (values.hasOwnProperty(key)) {
                this._printStyle.push((key + JSON.stringify(values[key])).replace(/['"]+/g, ''))
            }
        }
        this.returnStyleValues()
    }

    /**
     *
     *
     * @returns the string that create the stylesheet which will be injected
     * later within <style></style> tag.
     *
     * -join/replace to transform an array objects to css-styled string
     *
     * @memberof NgxPrintDirective
     */
    public returnStyleValues() {
        return `<style> ${this._printStyle.join(' ').replace(/,/g, ';')} 
        .printforce .print-invisible {
            display: none !important;
        }
        .printforce .print-only {
            display: block !important;
        }

        .printforce .bg-info {
            background: none !important;
        } 
        .printforce .text-white {
            color: #000 !important;
        }
        body {
            padding: 1rem;
        }
        td, th {
            padding: 0;
        }
       
        @page {
            size: a4 portrait;
        }
        // @page {
        //     size: auto !important;
        // }
       .fade-in {
           animation: none !important;
       }
        * {
            color: #000 !important; /* Black prints faster: h5bp.com/s */
            background-color: transparent !important;
            box-shadow: none !important;
            text-shadow: none !important;
        }
        .printforce .card {
            border-width: 1px !important;
            border-top-width: 1px !important;
        }
        
        .printforce .card {
            border-width: 1px !important;
            border-top-width: 1px !important;
        }

        .printforce .print-as-table {
            display: table !important;
        }
       
        .printforce .print-no-break {
            page-break-inside: avoid !important;
            margin-top: 1rem;
        }
        </style>`
    }

    /**
     *
     *
     * @returns html for the given tag
     *
     * @memberof NgxPrintDirective
     */
    private _styleSheetFile = ''

    /**
     * @memberof NgxPrintDirective
     * @param cssList
     */
    @Input()
    set styleSheetFile(cssList: string) {
        let linkTagFn = function (cssFileName) {
            return `<link rel="stylesheet" type="text/css" href="${cssFileName}">`
        }
        if (cssList.indexOf(',') !== -1) {
            const valueArr = cssList.split(',')
            for (let val of valueArr) {
                this._styleSheetFile = this._styleSheetFile + linkTagFn(val)
            }
        } else {
            this._styleSheetFile = linkTagFn(cssList)
        }
    }

    /**
     * @returns string which contains the link tags containing the css which will
     * be injected later within <head></head> tag.
     *
     */
    private returnStyleSheetLinkTags() {
        return this._styleSheetFile
    }
    private getElementTag(tag: keyof HTMLElementTagNameMap): string {
        const html: string[] = []
        const elements = document.getElementsByTagName(tag)
        for (let index = 0; index < elements.length; index++) {
            html.push(elements[index].outerHTML)
        }
        return html.join('\r\n')
    }

    /**
     * @returns html section to be printed along with some associated inputs
     *
     */
    private getHtmlContents() {
        let printContents = document.getElementById(this.printSectionId)
        let innards = printContents.getElementsByTagName('input')
        for (var i = 0; i < innards.length; i++) {
            innards[i].defaultValue = innards[i].value
        }
        return printContents.innerHTML
    }
    constructor(private env: Environment) {}
    @HostListener('click')
    public print(): void {
        let printContents,
            popupWin,
            styles = '',
            links = '',
            triggerCode = ''

        if (this.useExistingCss) {
            styles = this.getElementTag('style')
            links = this.getStyleLinks()
        }

        printContents = this.getHtmlContents()
        popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto')
        if (!popupWin) {
            alert(
                'Please enable popup windows for slovgen.sk to print the order. Thank you! / Povoľte popup okná pre Slovgen.sk aby ste mohli vytlačiť objednávku. Ďakujeme!'
            )
            return
        }
        if (this.env.production) {
            triggerCode = `
            <script>
                window.addEventListener('load', triggerPrint, false);
            </script>
            `
        } else {
            triggerCode = `
            <button id="trigger">Print</button> 
            <script>
                document.getElementById('trigger').addEventListener("click", triggerPrint);
            </script>
           `
        }
        popupWin?.document.open()
        // <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">

        popupWin?.document.write(`
      <html>
        <head>
          <title>${this.printTitle ? this.printTitle : ''}</title>

          ${this.returnStyleValues()}
          ${this.returnStyleSheetLinkTags()}
          ${styles}
          ${links}

        </head>
        <body class="printforce">
            <script>
            function triggerPrint(event) {
                window.removeEventListener('load', triggerPrint, false);
                setTimeout(function() {
                window.print();
                setTimeout(function() { window.close(); }, 0);
                }, ${this.printDelay});
            }
          </script>
          ${printContents}
          ${triggerCode}
        </body>
      </html>`)
        popupWin?.document.close()
    }
    getStyleLinks(): string {
        const html: string[] = []
        const elements = document.getElementsByTagName('link')
        for (let index = 0; index < elements.length; index++) {
            console.log(`CSS ${elements[index].href}`)
            // html.push(elements[index].outerHTML)
            html.push(`<link href="${elements[index].href}" rel="stylesheet">`)
            html.push(`<!-- asd dsa ${elements[index].href} jada -->`)
        }
        return html.join('\r\n<!-- jada jada -->')
    }
}
