import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'resultsgate-ui-note',
    template: `<div
        class="flex lg:align-items-start flex-column lg:flex-row   border-round border-1 "
        [ngClass]="{
            'bg-blue-100 border-blue-300 ': severity === 'info',
            'bg-yellow-100 border-yellow-300 ': severity === 'warn',
            'bg-red-100 border-red-300': severity === 'error',
            'p-4': size === 'm',
            'p-2': size === 's'
        }"
    >
        <div class="flex align-items-start">
            <i class="pi {{ icon ?? 'pi-check-circle' }} text-blue-900 text-2xl mr-3" *ngIf="severity === 'info'"></i>
            <i
                class="pi {{ icon ?? 'exclamation-triangle' }} text-yellow-900 text-2xl mr-3"
                *ngIf="severity === 'warn'"
            ></i>
            <i class="pi {{ icon ?? 'pi-times-circle' }} text-red-900 text-2xl mr-3" *ngIf="severity === 'error'"></i>
            <div class="mr-3 flex lg:align-items-start flex-column lg:flex-row">
                <div
                    *ngIf="title"
                    class="font-medium mr-0 lg:mr-2 mb-2 lg:mb-0 line-height-3"
                    [ngClass]="{
                        'text-blue-900 ': severity === 'info',
                        'text-yellow-900 ': severity === 'warn',
                        'text-red-900 ': severity === 'error'
                    }"
                >
                    <span *ngIf="title"> {{ title }}</span>
                    <ng-template #defaultTitle>
                        <ng-container [ngSwitch]="severity">
                            <span *ngSwitchCase="'info'">{{ 'common.noteInfo' | translate }}</span>
                            <span *ngSwitchCase="'warn'">{{ 'common.noteWarn' | translate }}</span>
                            <span *ngSwitchCase="'error'">{{ 'common.noteError' | translate }}</span>
                        </ng-container>
                    </ng-template>
                </div>
                <p
                    class="m-0 p-0 line-height-3"
                    [ngClass]="{
                        'text-blue-900 ': severity === 'info',
                        'text-yellow-900 ': severity === 'warn',
                        'text-red-900 ': severity === 'error'
                    }"
                >
                    {{ note }}
                    <ng-content></ng-content>
                </p>
            </div>
        </div>
    </div>`,
})
export class NoteComponent {
    @Input()
    severity: 'info' | 'warn' | 'error' = 'info'

    @Input()
    note?: string

    @Input()
    title?: string

    @Input()
    size: 's' | 'm' = 'm'

    @Input()
    icon?: string
}
