import { Component, HostListener, Input, OnInit } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { TranslateService } from '@ngx-translate/core'
import * as Sentry from '@sentry/angular-ivy'
import { Feedback } from '@sentry/angular-ivy'
import { filter } from 'rxjs/operators'
import { Environment } from '../../config/environment'
import { AuthService } from '../../services/auth.service'
import { ConfigService } from '../../services/config.service'
import { UtilitiesService } from '../../services/utilities.service'

@Component({
    selector: 'ui-layout',
    templateUrl: './default-layout.component.html',
    styleUrls: ['./default-layout.component.css'],
})
export class DefaultLayoutComponent implements OnInit {
    public sidebarShowMobile = false
    public sidebarShowLG = true
    sidebarBackdrop = false
    internaltest = false
    @Input()
    public sideBarMenuItems
    @Input() currentComponent: any
    breadcrumbActionFromChild: any
    year: number = new Date().getFullYear()
    admin: boolean
    constructor(
        private router: Router,
        private authService: AuthService,
        private ngxTranslate: TranslateService,
        private utilitiesService: UtilitiesService,
        private configService: ConfigService,
        private env: Environment
    ) {
        this.internaltest = this.configService.getConfig().stage === 'DEV'
        this.admin = this.env.admin
    }

    onMenuClick(event: Event) {
        if (this.sidebarBackdrop) {
            this.toggleMinimize(event)
        }
    }

    @HostListener('document:click', ['$event'])
    documentClick(event: any): void {
        this.utilitiesService.documentClickedTarget.next(event.target)
    }

    toggleMinimize(e) {
        this.sidebarShowMobile = !this.sidebarShowMobile
        this.sidebarBackdrop = this.sidebarShowMobile
    }
    toggleSidebarLG(e: Event) {
        this.sidebarShowLG = !this.sidebarShowLG
        this.sidebarBackdrop = this.sidebarShowMobile
    }
    backdropClick(event: Event) {
        this.toggleMinimize(!this.sidebarShowMobile)
    }
    documentClickListener(target: any): void {
        // if (this.dropdownMenu.nativeElement.contains(target)) {
        //     // Clicked inside
        //     this.dropdownMenu.nativeElement.classList.add('show')
        // } else {
        // }
    }
    ngOnInit() {
        this.utilitiesService.documentClickedTarget.subscribe((target) => this.documentClickListener(target))
        // this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: Event) => {})

        // this.route.data.subscribe(data => {
        //     console.log(`Route change: shell ${data.shell}`)
        //     this.shell = !(data.shell === false)
        // })
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            if (this.currentComponent) {
                // console.log(
                //     `in container actin template: ${this.currentComponent.constructor.name} ${this.currentComponent.breadcrumbAction}`
                // )
                this.breadcrumbActionFromChild = this.currentComponent.breadcrumbAction
            }
        })
    }

    logout(event: Event) {
        this.authService.logout()
    }

    showSentryFeedback() {
        console.log(`showSentryFeedback ${this.ngxTranslate.currentLang}`)
        const feedback = Sentry.getClient().getIntegration(Feedback)
        const lang = this.ngxTranslate.currentLang

        feedback.options.buttonLabel = lang === 'sk' ? 'SK ' : 'EN'
        //translate all labels
        feedback.options.cancelButtonLabel = this.ngxTranslate.instant('feedback.cancelButtonLabel')
        feedback.options.emailLabel = this.ngxTranslate.instant('feedback.emailLabel')
        feedback.options.emailPlaceholder = this.ngxTranslate.instant('feedback.emailPlaceholder')
        feedback.options.formTitle = this.ngxTranslate.instant('feedback.formTitle')
        feedback.options.nameLabel = this.ngxTranslate.instant('feedback.nameLabel')
        feedback.options.namePlaceholder = this.ngxTranslate.instant('feedback.namePlaceholder')
        feedback.options.messageLabel = this.ngxTranslate.instant('feedback.messageLabel')
        feedback.options.messagePlaceholder = this.ngxTranslate.instant('feedback.messagePlaceholder')
        feedback.options.submitButtonLabel = this.ngxTranslate.instant('feedback.submitButtonLabel')
        feedback.options.successMessageText = this.ngxTranslate.instant('feedback.successMessageText')

        feedback.remove()
        feedback.setupOnce()
        feedback.openDialog()
    }
}
