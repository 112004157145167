import { Injectable } from '@angular/core'
import { Config } from '../../models/config'
import { Environment } from '../config/environment'

@Injectable({ providedIn: 'root' })
export class ConfigService {
    private config: Config
    constructor(private env: Environment) {
        this.config = {
            localStoragePrefix: 'SP_',
            showDebugInfo: '',
            stage: 'PROD',
            systemName: 'portal.slovgen.sk',
        }
    }
    init() {
        const configFromEnv = (window as typeof window & { APP_CONFIG?: { [k: string]: string } })['APP_CONFIG']
        if (configFromEnv) {
            Object.keys(configFromEnv)
                .filter((k) => !configFromEnv[k].startsWith('$'))
                .forEach((k) => Object.assign(this.config, { [k]: configFromEnv[k] }))
        }
        console.log(`🌈 App config done: ${JSON.stringify(this.config)}`)
    }

    getConfig() {
        return this.config
    }
}
