import { Component, OnInit, ChangeDetectionStrategy, Input, TemplateRef, Optional } from '@angular/core'
import { DefaultLayoutComponent } from './default-layout'

@Component({
    selector: 'ui-page',
    template: `
        <ng-template #content>
            <ng-content></ng-content>
        </ng-template>
        <div class="fade-in position-relative">
            <ng-container *ngIf="card; else content">
                <div class="card">
                    <div class="card-header">{{ pageTitle }}</div>
                    <div class="card-body">
                        <ng-container *ngTemplateOutlet="content"></ng-container>
                    </div>
                    <div class="card-footer" *ngIf="withFooter"><ng-content select="[footer]"></ng-content></div>
                </div>
            </ng-container>
        </div>
    `,
    styles: [],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent implements OnInit {
    @Input() breadcrumbAction: TemplateRef<any>
    @Input() card = true
    @Input() withFooter = false
    @Input() pageTitle: string
    constructor(@Optional() private parent: DefaultLayoutComponent) {}

    ngOnInit() {
        // console.log(`in PageComponent: breadcrumb: ${this.parent} actintemplate ${this.breadcrumbAction}`)

        if (this.breadcrumbAction) {
            setTimeout(() => {
                this.parent.breadcrumbActionFromChild = this.breadcrumbAction
            })
        }
    }
}
